:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkgray: #464d45;
  --calory: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appcolor: #3c3f45;
}

.App {
  /* background: var(--darkgray); */
  background: rgb(92, 92, 92);
  mix-blend-mode: overlay;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-transform: uppercase;
}

.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  cursor: pointer;
}

.blur {
  background-color: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}
